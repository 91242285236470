import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Layout from "../components/dfa-theme/layout";
import ContentBlock from "../components/content-block";
import SanityBlog from "../components/sanity-blog";
import { Grid, Row, Col } from "react-flexbox-grid";
import { CONSTANTS } from "../utils/constants";
import deer from '../images/deer-icon.png';
import { MyPortableText } from "../components/myPortableText";
import BadgeComponent from '../components/badge-component';

import "./blog-post.scss";

export const query = graphql`
  query BlogPostBySlug($slug: String!, $date: Date!) {
    sanityPost(slug: { current: { eq: $slug } }) {
      _id
      title
      _rawSubhead(resolveReferences: { maxDepth: 5 })
      body {
        _rawChildren
      }
      categories {
        title
      }
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
      date: publishedAt
      imageUrl: mainImage {
        asset {
          url
        }
      }
    }
    prevPost: allSanityPost(
      filter: { publishedAt: { lt: $date } }
      sort: { fields: publishedAt, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    nextPost: allSanityPost(
      filter: { publishedAt: { gt: $date } }
      sort: { fields: publishedAt, order: ASC }
      limit: 1
    ) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }
    firstPost: allSanityPost(sort: { fields: publishedAt, order: ASC }, limit: 1) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }

    lastPost: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 1) {
      edges {
        node {
          title
          slug {
            current
          }
        }
      }
    }

  }
`;

const BlogPostTemplate = ({ data }) => {
  const metaTags = {
    name: "News & Viewpoints",
    description: "Deerfield Viewpoints Blog Post",
    keywords: "Deerfield, Viewpoints, Blog, Post, News",
  };

  const post = data.sanityPost;

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  return (
    <Layout meta={metaTags}>
      <div className="blog-hero-container">
        <ContentBlock
          outerBgColor={CONSTANTS.WHITE}
          className="blog-header block"
        >
          <Grid>
            <Row>
              <Col>
                <Link to="/news" className="back-link">back to news & viewpoints</Link>
              </Col>
            </Row>

            {/* Blog title & header contents */}
            <Row>
              <Col xs={1} md={2}></Col>
              <Col xs={10} md={8} className="blog-title-block">
                <div>
                  <p className="header-orange">news & viewpoints</p>
                  <h1>{post.title}</h1>
                  {post._rawSubhead && (
                    <div className="subhead">
                      <MyPortableText value={post._rawSubhead} />
                    </div>
                  )}
                  <h2>
                    {formatDate(post.date)}
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className="bullet">&bull;</span>&nbsp;&nbsp;&nbsp;&nbsp;
                    {post.categories && post.categories.length > 0
                      ? post.categories.map((cat) => cat.title).join(", ")
                      : ""}
                  </h2>
                </div>
              </Col>
              <Col xs={1} md={2}></Col>
            </Row>
          </Grid>
        </ContentBlock>
      </div>

      {/* Blog body contents */}
      <div className="blog-body">
        <ContentBlock outerBgColor={CONSTANTS.WHITE} className="block">
          <Grid>
            <Row>
              <Col xs={0} md={2}></Col>
              <Col xs={12} md={8}>
                <div className="article-content-wrapper">
                  <img src={post.imageUrl.asset.url} alt={post.title} />
                  <MyPortableText value={post?._rawBody} />

                  <div className="article-links">
                    {(data.prevPost?.edges?.[0] || data.lastPost?.edges?.[0]) && (
                      <Link to={`/news/${(data.prevPost?.edges?.[0] || data.lastPost?.edges?.[0]).node.slug.current}`} className="previous-article">
                        ← previous article
                      </Link>
                    )}

                    <img src={deer} alt="Deer Icon" className="deer-icon" />

                    {(data.nextPost?.edges?.[0] || data.firstPost?.edges?.[0]) && (
                      <Link to={`/news/${(data.nextPost?.edges?.[0] || data.firstPost?.edges?.[0]).node.slug.current}`} className="next-article">
                        next article →
                      </Link>
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={0} md={2}></Col>
            </Row>
          </Grid>
        </ContentBlock>
      </div>

      <ContentBlock
        outerBgColor={CONSTANTS.LIGHT_GRAY}
        className="block block-extended"
      >
        <Grid>
          <Row>
            <Col xs={1}></Col>
            <Col xs={10} className="blog-callout">
              <h2>More news & viewpoints</h2>
              <Grid>
                <SanityBlog numberOfBlogs={2} excludePostId={data.sanityPost._id} showMoreButton={false} />
                <Link className='dfa-button-orange' to='/news'>
                  all articles
                </Link>
              </Grid>
            </Col>
          </Row>
        </Grid>
      </ContentBlock>
      <BadgeComponent isVisible={true} />
    </Layout>
  );
};

export default BlogPostTemplate;
