import React from 'react';
import { PortableText } from '@portabletext/react';

const components = {
	marks: {
		sup: ({ children }) => <sup>{children}</sup>,
		internalLink: ({ value, children }) => {
			const href = `${value.reference.pageRelativeURL}`;
			return <a href={href}>{children}</a>;
		},
		author: ({ children }) => {
			// This will render the text wrapped in the 'author' decorator as a <span> with a specific class
			return <span className="author-name">{children}</span>;
		},
		// open external links in new tab
		link: ({ value, children }) => {
			// Helper function to check if a URL is external
			const isExternal = (url) => {
				// Simple regex to check if the URL starts with 'http://' or 'https://'
				return /^https?:\/\//.test(url);
			};

			// Extract the href value from the link
			const href = value.href;

			// Check if the link is external
			if (isExternal(href)) {
				// If external, open in new tab
				return (
					<a href={href} target="_blank" rel="noopener noreferrer">
						{children}
					</a>
				);
			} else {
				// If internal, open in the same tab
				return <a href={href}>{children}</a>;
			}
		},
		// link: ({ value, children }) => {
		// 	const { blank, href } = value;
		// 	return blank ? (
		// 		<ExternalLink href={value.external ? value.external : href}>
		// 			{children}
		// 		</ExternalLink>
		// 	) : (
		// 		<a href={value.external ? value.external : href}>{children}</a>
		// 	);
		// },
	},
	types: {
		span: ({ value, children }) => {
			return <span>{children}</span>;
		},
		image: ({ value }) => {
			// Log the value to see what data is being passed
			console.log('Image value:', value);

			if (!value || !value.asset || !value.asset.url) {
				// Return null or a placeholder if the image is not set properly
				return null;
			}
			return (
				<img
					src={value.asset.url}
					alt={value.alt || ' '}
				// add more properties here, like width and height
				/>
			);
		},
	},
	block: {
		normal: ({ children }) => <p>{children}</p>,
		h1: ({ children }) => <h1>{children}</h1>,
		h2: ({ children }) => <h2>{children}</h2>,
		h3: ({ children }) => <h3>{children}</h3>,
		h4: ({ children }) => <h4>{children}</h4>,
		h5: ({ children }) => <h5>{children}</h5>, // Add this line
		blockquote: ({ children }) => <blockquote>{children}</blockquote>,
	},
};

export const MyPortableText = (props) => {
	return <PortableText value={props.value} components={components} />;
};
